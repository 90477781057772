import { EventData } from '@havenengineering/module-owners-shared-library/dist/components/EventCalendar';
import { getEventDays } from '@havenengineering/module-owners-shared-library/dist/utils/';
import { DateTime, Interval } from 'luxon';

import { InfoSelection } from '../../components/EventCalendarApendix/InfoPanel';
import {
  CANCEL_ARRIVAL_ERROR_MESSAGE,
  hasPlayPassTrial,
  isLetWithHaven,
  isUnavailable,
  REMOVE_BREAK_ERROR_MESSAGE,
  REMOVE_LET_2_OWN_BELOW_MINIMUM_ERROR_MESSAGE,
  REMOVE_LET_TIME_ERROR_MESSAGE,
} from '../../helpers/calendar';
import { getDurationInDays } from '../../helpers/dateTime';
import {
  fetchWrapper,
  withApiBaseUrl,
  withArrivalsApiBaseUrl,
} from '../../helpers/fetch';
import {
  handleArrivalBookingOnCalendarGTM,
  handleGTMArrivalManagement,
} from '../../helpers/googleTag';
import { ArrivalDataEnum, BreakDataAction } from '../../pages/bookings';
import { BreakVisitType } from '../../types/lettings';
import { BookingType } from '../EventCalendarApendix/Booking';

export const updateBooking = async (
  body: UpdateBookingBody,
  bookingDetails: ArrivalBooking
) => {
  const response = await fetchWrapper(
    withArrivalsApiBaseUrl(
      `/booking/${bookingDetails.shortCode}/scheduled-arrival`
    ),
    {
      method: 'PUT',
      credentials: 'include',
      body: JSON.stringify(body),
    }
  );
  return response;
};

export interface SwappableBreak {
  startDate: string;
  endDate: string;
  status: number;
  value: string;
  packageId: number;
  breakDaysType: BreakDaysType;
  breakPeakType: ParkPeakType;
}

export interface SwappableBreaksResponse {
  [key: string]: SwappableBreak[];
}

export const fetchSwappableBreaks = async (
  accountId: number,
  startDate: string
) =>
  await fetchWrapper<SwappableBreaksResponse | null>(
    withApiBaseUrl(
      `/plot/${accountId}/swappable-breaks?startDate=${startDate}`
    ),
    {
      method: 'GET',
      credentials: 'include',
    }
  );

export const sendBreakSwap = async (
  accountId: number,
  packageId: number,
  newPackageId: number
) =>
  await fetchWrapper<SwappableBreaksResponse>(
    withApiBaseUrl(`/plot/${accountId}/swappable-breaks`),
    {
      method: 'PUT',
      credentials: 'include',
      body: JSON.stringify({
        packageId,
        newPackageId,
      }),
    }
  );

export const resendBookingEmail = async (
  shortCode: string,
  letterEmail: string,
  ownerEmail: string
) =>
  await fetchWrapper(
    withArrivalsApiBaseUrl(`/booking/${shortCode}/user/confirmationEmail`),
    {
      method: 'PUT',
      credentials: 'include',
      body: JSON.stringify({
        letterEmail,
        ownerEmail,
      }),
    }
  );

export const getPrivateGuestEmailConfirmationInfo = async (shortCode: string) =>
  await fetchWrapper(
    withArrivalsApiBaseUrl(
      `/booking/${shortCode}/user/leadGuest?select[]=confirmationEmailResentAt&select[]=email`
    ),
    {
      credentials: 'include',
    }
  );

export const cancelBooking = async (
  bookingDetails: ArrivalBooking,
  account: UserAccount | null
) => {
  try {
    await fetchWrapper(
      withArrivalsApiBaseUrl(`/booking/${bookingDetails.shortCode}`),
      {
        method: 'DELETE',
        credentials: 'include',
      }
    );
    await handleGTMArrivalManagement(
      {
        ...bookingDetails,
        isCancelled: true,
        accountNo: account?.accountNo,
        areaName: account?.areaName,
        pitchNumber: account?.pitchNumber,
        successFlag: 'success',
      },
      'arrivals cancel stay confirmation'
    );
  } catch (error) {
    await handleGTMArrivalManagement(
      {
        ...bookingDetails,
        isCancelled: false,
        accountNo: account?.accountNo,
        areaName: account?.areaName,
        pitchNumber: account?.pitchNumber,
        successFlag: 'error',
        errorType: error?.message,
      },
      'arrivals cancel stay confirmation'
    );
    throw error;
  }
};

export const upsertParticipants = async (
  body: UpsertParticipantsBody,
  bookingDetails: ArrivalBooking,
  account: UserAccount | null
) => {
  try {
    const response = await fetchWrapper(
      withArrivalsApiBaseUrl(
        `/booking/${bookingDetails.shortCode}/user/additionalGuests`
      ),
      {
        method: 'PUT',
        credentials: 'include',
        body: JSON.stringify(body),
      }
    );
    await handleGTMArrivalManagement(
      {
        ...bookingDetails,
        users: response.participants,
        childNumber: response.childNumber,
        toddlersNumber: response.toddlersNumber,
        accountNo: account?.accountNo,
        areaName: account?.areaName,
        pitchNumber: account?.pitchNumber,
        successFlag: 'success',
      },
      'arrivals edit stay save changes'
    );
    return response;
  } catch (error) {
    await handleGTMArrivalManagement(
      {
        ...bookingDetails,
        accountNo: account?.accountNo,
        areaName: account?.areaName,
        pitchNumber: account?.pitchNumber,
        successFlag: 'error',
        errorType: error?.message,
      },
      'arrivals edit stay save changes'
    );
    throw error;
  }
};

export const downloadCarpass = async (
  bookingDetails: ArrivalBooking,
  account: UserAccount | null
) => {
  try {
    const response = await fetchWrapper(
      withArrivalsApiBaseUrl(`/booking/${bookingDetails.shortCode}/carPass`),
      {
        method: 'GET',
        credentials: 'include',
        headers: {
          Accept: 'application/pdf',
        },
      }
    );

    await handleGTMArrivalManagement(
      {
        ...bookingDetails,
        accountNo: account?.accountNo,
        areaName: account?.areaName,
        pitchNumber: account?.pitchNumber,
        successFlag: 'success',
      },
      'download car pass'
    );
    return response;
  } catch (error) {
    await handleGTMArrivalManagement(
      {
        ...bookingDetails,
        accountNo: account?.accountNo,
        areaName: account?.areaName,
        pitchNumber: account?.pitchNumber,
        successFlag: 'error',
        errorType: error?.message,
      },
      'download car pass'
    );
    throw error;
  }
};

export const deleteArrivalBooking = async (booking: ArrivalBooking) => {
  const response = {
    success: false,
    error: '',
    info: '',
  };

  try {
    await fetchWrapper(
      withArrivalsApiBaseUrl(`/booking/${booking.shortCode}`),
      {
        method: 'DELETE',
        credentials: 'include',
      }
    );
    response.success = true;

    handleArrivalBookingOnCalendarGTM(booking, 'cancel arrival');
  } catch (error) {
    response.error = CANCEL_ARRIVAL_ERROR_MESSAGE;
  }

  return response;
};

export const deleteArrivalBookingOrHavenLet = async (
  accountId: number,
  eventSelection: EventData,
  allPeakDatesData: PeakDatesData[],
  let2OwnSummary: Let2OwnSummary | undefined
): Promise<{ success: boolean; error: string; info: string }> => {
  const response = {
    success: false,
    error: '',
    info: '',
  };

  if (eventSelection.code === ArrivalDataEnum.ARRIVAL_BOOKING) {
    const booking = eventSelection.data as ArrivalBooking;
    return await deleteArrivalBooking(booking);
  }

  if (accountId && eventSelection.code !== ArrivalDataEnum.ARRIVAL_BOOKING) {
    try {
      const code = eventSelection.code;
      const startDate = eventSelection.data.startDate;
      const { outOfSeason, withinSixWeeks } = eventSelection.data.status;

      if (isLetWithHaven(code) && withinSixWeeks) {
        response.info = REMOVE_LET_TIME_ERROR_MESSAGE;
        return response;
      }

      if (
        isBreakRemovalDisabled(eventSelection, allPeakDatesData, let2OwnSummary)
      ) {
        response.info = REMOVE_LET_2_OWN_BELOW_MINIMUM_ERROR_MESSAGE;
        return response;
      }

      const transitions: BreakTransition[] = await fetchWrapper(
        withApiBaseUrl(
          `/plot/${accountId}/lettingbreaks/status?status=${code}&startDate=${startDate}&outOfSeason=${outOfSeason}`
        ),
        {
          method: 'GET',
          credentials: 'include',
        }
      );
      if (transitions.length > 0) {
        const removeBreakTransition = transitions.find(
          (tr: BreakTransition) => tr.action === BreakDataAction.REMOVE_LET
        );

        if (!removeBreakTransition) {
          response.error = REMOVE_BREAK_ERROR_MESSAGE;
          return response;
        }
        const success = await performBreakDataChange(accountId, [
          {
            startDate: eventSelection.data.startDate,
            status: removeBreakTransition.code,
          },
        ]);
        if (success) {
          response.success = true;
        } else {
          response.error = REMOVE_BREAK_ERROR_MESSAGE;
        }
      } else {
        response.error = REMOVE_BREAK_ERROR_MESSAGE;
      }
    } catch (error) {
      response.error = REMOVE_BREAK_ERROR_MESSAGE;
    }
  }

  return response;
};

export const performBreakDataChange = async (
  accountId: number,
  breaks: BreakChange[]
) => {
  if (accountId) {
    try {
      await fetchWrapper(withApiBaseUrl(`/plot/${accountId}/lettingbreaks`), {
        method: 'PUT',
        credentials: 'include',
        body: JSON.stringify({
          breaks: breaks,
        }),
      });
      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  }
};

export const isBreakRemovalDisabled = (
  eventSelection: EventData,
  allPeakDatesData: PeakDatesData[],
  let2OwnSummary: Let2OwnSummary | undefined
) => {
  const breakDaysType = getBreakDaysType(
    eventSelection.data.startDate,
    eventSelection.data.endDate
  );

  const peakType = getBreakPeakType(
    eventSelection.data.startDate,
    eventSelection.data.endDate,
    allPeakDatesData
  );

  return (
    (isLetWithHaven(eventSelection.code) &&
      peakType &&
      breakDaysType &&
      let2OwnSummary &&
      let2OwnSummary.isLet2Own &&
      let2OwnSummary.summary[peakType][breakDaysType].current - 1 <
        let2OwnSummary.summary[peakType][breakDaysType].min) ||
    false
  );
};

export const getPrevConsecutiveArrival = (
  startDate: DateTime,
  endDate: DateTime,
  arrivals: EventData<ArrivalBooking>[]
) => {
  return arrivals
    .filter((arrival) => {
      const arrivalStartDate = DateTime.fromISO(
        arrival.data!.dateArrival
      ).startOf('day');
      const arrivalEndDate = DateTime.fromISO(
        arrival.data!.dueToLeaveDate
      ).startOf('day');

      return (
        arrivalStartDate < startDate &&
        arrivalEndDate < endDate &&
        arrivalEndDate >= startDate.minus({ days: 1 })
      );
    })
    .reduce(
      (longestArrival: EventData<ArrivalBooking> | undefined, arrival) => {
        if (longestArrival) {
          const arrivalStartDate = DateTime.fromISO(
            arrival.data!.dateArrival
          ).startOf('day');
          const longestArrivalStartDate = DateTime.fromISO(
            longestArrival.data!.dateArrival
          ).startOf('day');
          const dayCountBeforeStartDate = getDurationInDays(
            arrivalStartDate,
            startDate
          );
          const longestDayCountBeforeStartDate = getDurationInDays(
            longestArrivalStartDate,
            startDate
          );

          return dayCountBeforeStartDate > longestDayCountBeforeStartDate
            ? arrival
            : longestArrival;
        } else {
          return arrival;
        }
      },
      undefined
    );
};

export const getNextConsecutiveArrival = (
  startDate: DateTime,
  endDate: DateTime,
  arrivals: EventData<ArrivalBooking>[]
) => {
  return arrivals
    .filter((x) => {
      const arrivalStartDate = DateTime.fromISO(x.data!.dateArrival).startOf(
        'day'
      );
      const arrivalEndDate = DateTime.fromISO(x.data!.dueToLeaveDate).startOf(
        'day'
      );

      return (
        arrivalStartDate > startDate &&
        arrivalEndDate > endDate &&
        arrivalStartDate <= endDate.plus({ days: 1 })
      );
    })
    .reduce(
      (longestArrival: EventData<ArrivalBooking> | undefined, arrival) => {
        if (longestArrival) {
          const arrivalEndDate = DateTime.fromISO(
            arrival.data!.dueToLeaveDate
          ).startOf('day');
          const longestArrivalEndDate = DateTime.fromISO(
            longestArrival.data!.dueToLeaveDate
          ).startOf('day');
          const dayCountAfterEndDate = getDurationInDays(
            endDate,
            arrivalEndDate
          );
          const longestDayCountAfterEndDate = getDurationInDays(
            endDate,
            longestArrivalEndDate
          );

          return dayCountAfterEndDate > longestDayCountAfterEndDate
            ? arrival
            : longestArrival;
        } else {
          return arrival;
        }
      },
      undefined
    );
};

export const buildConsecutiveArrivalsInThePast = (
  startDate: DateTime,
  endDate: DateTime,
  arrivals: EventData<ArrivalBooking>[]
) => {
  const consecutiveArrivalsInThePast = [];

  let prevConsecutiveArrival = getPrevConsecutiveArrival(
    startDate,
    endDate,
    arrivals
  );

  while (prevConsecutiveArrival) {
    consecutiveArrivalsInThePast.push(prevConsecutiveArrival);
    prevConsecutiveArrival = getPrevConsecutiveArrival(
      DateTime.fromISO(prevConsecutiveArrival.data!.dateArrival).startOf('day'),
      DateTime.fromISO(prevConsecutiveArrival.data!.dueToLeaveDate).startOf(
        'day'
      ),
      arrivals
    );
  }

  return consecutiveArrivalsInThePast;
};

export const buildConsecutiveArrivalsInTheFuture = (
  startDate: DateTime,
  endDate: DateTime,
  arrivals: EventData<ArrivalBooking>[]
) => {
  const consecutiveArrivalsInTheFuture = [];

  let nextConsecutiveArrival = getNextConsecutiveArrival(
    startDate,
    endDate,
    arrivals
  );

  while (nextConsecutiveArrival) {
    consecutiveArrivalsInTheFuture.push(nextConsecutiveArrival);
    nextConsecutiveArrival = getNextConsecutiveArrival(
      DateTime.fromISO(nextConsecutiveArrival.data!.dateArrival).startOf('day'),
      DateTime.fromISO(nextConsecutiveArrival.data!.dueToLeaveDate).startOf(
        'day'
      ),
      arrivals
    );
  }

  return consecutiveArrivalsInTheFuture;
};

export const buildConsecutiveArrivalsChain = (
  startDate: DateTime,
  endDate: DateTime,
  arrivals: EventData<ArrivalBooking>[]
): EventData<ArrivalBooking>[] => {
  return [
    ...buildConsecutiveArrivalsInThePast(
      startDate,
      endDate,
      arrivals
    ).reverse(),
    {
      id: 'none',
      days: getEventDays(startDate, endDate),
      data: {
        dateArrival: startDate,
        dueToLeaveDate: endDate,
        isCancelled: false,
      } as unknown as ArrivalBooking,
      styleType: 'normal' as const,
      code: ArrivalDataEnum.ARRIVAL_BOOKING,
      label: 'you',
      order: 4,
      bannerStyles: {
        color: 'white',
        backgroundColor: '#29822F',
        borderColor: '#29822F',
      },
    },
    ...buildConsecutiveArrivalsInTheFuture(startDate, endDate, arrivals),
  ];
};

export const wouldArrivalsBeAboveTheMaximumConsecutiveDays = (
  startDate: DateTime,
  endDate: DateTime,
  arrivals: EventData<ArrivalBooking>[],
  maxConsecutiveDays: number
) => {
  const consecutiveArrivalsChain = buildConsecutiveArrivalsChain(
    startDate.startOf('day'),
    endDate.startOf('day'),
    arrivals
  );

  const startDateOfChain = DateTime.fromISO(
    consecutiveArrivalsChain[0].data!.dateArrival
  ).startOf('day');
  const endDateOfChain = DateTime.fromISO(
    consecutiveArrivalsChain[consecutiveArrivalsChain.length - 1].data!
      .dueToLeaveDate
  ).startOf('day');

  return (
    getDurationInDays(startDateOfChain, endDateOfChain) > maxConsecutiveDays
  );
};

export interface BreakTypeSummary {
  min: number;
  max: number;
  current: number;
  isValid: boolean;
}

export interface PeakTypeSummary {
  midweek: BreakTypeSummary;
  weekend: BreakTypeSummary;
}

export interface Let2OwnSummary {
  isLet2Own: boolean;
  summary: {
    offPeak: PeakTypeSummary;
    peak: PeakTypeSummary;
    superPeak: PeakTypeSummary;
  };
}

export enum ParkPeakType {
  OFF_PEAK = 'offPeak',
  PEAK = 'peak',
  SUPER_PEAK = 'superPeak',
}

export enum BreakDaysType {
  MIDWEEK = 'midweek',
  WEEKEND = 'weekend',
}

export interface SelectedBreakTransition {
  startDate: string;
  endDate: string;
  label: string;
  parkLettingPackageId?: number;
  status: number;
  originalLabel: string;
  outOfSeason: boolean;
  checked: boolean;
  removeActionStatusCode?: number;
  disabled: boolean;
  daysType: BreakDaysType | null;
  peakType: ParkPeakType | null;
  capWaitlist?: boolean;
}

export const getBreakDaysType = (
  startDate: string,
  endDate: string
): BreakDaysType | null => {
  const startDatetime = DateTime.fromISO(startDate)
    .startOf('day')
    .setZone('Europe/London');
  const endDateTime = DateTime.fromISO(endDate)
    .startOf('day')
    .setZone('Europe/London');

  switch (endDateTime.diff(startDatetime, 'days').days) {
    case 4:
      return BreakDaysType.MIDWEEK;
    case 3:
      return BreakDaysType.WEEKEND;
    default:
      return null;
  }
};

export const getBreakPeakType = (
  startDate: string,
  endDate: string,
  allPeakDatesData: PeakDatesData[]
): ParkPeakType => {
  const peakDate = allPeakDatesData.find(
    (x) => x.startDate === startDate && x.endDate === endDate
  );

  const breakPeakType = peakDate
    ? getPeakTypeFromPeakDate(peakDate)
    : ParkPeakType.OFF_PEAK;

  return breakPeakType!;
};

export const getPeakTypeFromPeakDate = (
  parkPeakDate: PeakDatesData | null | undefined
) => {
  if (!parkPeakDate) {
    return null;
  }

  if (parkPeakDate.isSuperPeakDate) {
    return ParkPeakType.SUPER_PEAK;
  } else if (parkPeakDate.isPeakDate) {
    return ParkPeakType.PEAK;
  } else {
    return ParkPeakType.OFF_PEAK;
  }
};

export const getPeakTypeLabelText = (peakType: ParkPeakType | null) => {
  const peakText =
    peakType === ParkPeakType.PEAK
      ? 'PEAK'
      : peakType === ParkPeakType.SUPER_PEAK
      ? 'SUPER PEAK'
      : 'OFF-PEAK';

  return peakText;
};

export const getBreakDaysTypeLabelText = (
  breakDaysType: BreakDaysType | null
) => {
  return breakDaysType?.toUpperCase();
};

export const getBreakTypesLabelText = (
  startDate: string,
  endDate: string,
  allPeakDatesData: PeakDatesData[]
) => {
  startDate = DateTime.fromISO(startDate).toFormat('yyyy-MM-dd');
  endDate = DateTime.fromISO(endDate).toFormat('yyyy-MM-dd');

  const breakDaysType = getBreakDaysType(startDate, endDate);

  const peakType = getBreakPeakType(startDate, endDate, allPeakDatesData);

  const peakText = getPeakTypeLabelText(peakType);

  const dayTypeText = getBreakDaysTypeLabelText(breakDaysType);

  return `${peakText} (${dayTypeText})`;
};

export const getBreakLabel = (
  startDate: string,
  endDate: string,
  breakVisitType: BreakVisitType,
  allPeakDatesData: PeakDatesData[],
  isLet2Own = false
) => {
  if (
    isLet2Own &&
    [BreakVisitType.LET_WITH_HAVEN, BreakVisitType.WAITLIST].includes(
      breakVisitType
    )
  ) {
    return getBreakTypesLabelText(startDate, endDate, allPeakDatesData);
  } else if (breakVisitType === BreakVisitType.MAINTENANCE) {
    return '—';
  } else {
    const duration =
      Interval.fromDateTimes(
        DateTime.fromISO(startDate),
        DateTime.fromISO(endDate)
      ).count('days') - 1 || 0;

    return `${duration} NIGHT${duration > 1 ? 'S' : ''}`;
  }
};

export const getBreakTransitions = async (
  accountId: number,
  code: number | undefined,
  startDate: string,
  outOfSeason: string
) => {
  try {
    const transitions = await fetchWrapper(
      withApiBaseUrl(
        `/plot/${accountId}/lettingbreaks/status?status=${code}&startDate=${startDate}&outOfSeason=${outOfSeason}`
      ),
      {
        method: 'GET',
        credentials: 'include',
      }
    );
    return transitions as BreakTransition[];
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const getBreakDataForSelection = async (
  accountId: number,
  selection: InfoSelection
) => {
  const { startDate, endDate } = selection.dates;

  const breaksQuery = new URLSearchParams();
  breaksQuery.append('startDate', startDate.toFormat('yyyy-MM-dd'));
  breaksQuery.append('endDate', endDate.toFormat('yyyy-MM-dd'));
  breaksQuery.append('includeOverlap', 'true');

  try {
    return await fetchWrapper(
      withApiBaseUrl(
        `/plot/${accountId}/lettingbreaks?${breaksQuery.toString()}`
      ),
      {
        method: 'GET',
        credentials: 'include',
      }
    );
  } catch (error) {
    console.error(error);
  }
};

export const evaluateHavenLetsInSelection = (
  selection: InfoSelection,
  type?: BookingType
) => {
  const evaluation = {
    overlapWithHavenLet: false,
    withinSixWeeks: false,
    overlapWithPexHandover: false,
  };

  const havenLetsInSelection = selection.events?.filter((event) =>
    isLetWithHaven(event.code)
  );

  if (!havenLetsInSelection?.length) {
    return evaluation;
  }

  evaluation.overlapWithPexHandover = havenLetsInSelection.some((havenLet) =>
    isUnavailable(havenLet.data.status)
  );

  const mappedHavenLets = havenLetsInSelection.map((havenLet) => {
    const startDateTime = DateTime.fromISO(havenLet?.data?.startDate);
    const endDateTime = DateTime.fromISO(havenLet?.data?.endDate);

    return {
      start: startDateTime.plus({ days: 1 }).toMillis(),
      end: endDateTime.minus({ days: 1 }).toMillis(),
    };
  });

  evaluation.withinSixWeeks =
    havenLetsInSelection[0]?.data?.status?.withinSixWeeks;
  const { startDate, endDate } = selection?.dates;

  const bypassOverlapCheck =
    type === BookingType.OWNER && startDate === endDate;

  evaluation.overlapWithHavenLet =
    !bypassOverlapCheck &&
    mappedHavenLets.some(
      (havenLet) =>
        startDate?.toMillis() <= havenLet.end &&
        havenLet.start <= endDate?.toMillis()
    );

  return evaluation;
};

export const sortBookingDataByArrivalDate = (arrivalData: EventData<any>[]) =>
  arrivalData.sort(
    (a, b) =>
      DateTime.fromISO(a.data.startDate || a.data.dateArrival).toMillis() -
      DateTime.fromISO(b.data.startDate || b.data.dateArrival).toMillis()
  );

export const getGuestSelectorOptions = ({
  ownersCards,
  parkCode,
  isPlayPassRestricted,
}: {
  ownersCards: OwnersCardResponse | null;
  parkCode?: string;
  isPlayPassRestricted: boolean;
}) => {
  if (!ownersCards) {
    return [
      {
        label: 'Friends & Family Card Holder',
        value: 'privilege_card_holder',
      },
      isPlayPassRestricted
        ? null
        : {
            label: 'Include option to purchase a Play Pass Online',
            value: 'include_option_to_purchase_a_play_pass_online',
          },
    ].filter((option) => !!option);
  }
  return ownersCards.accountIsCharity
    ? []
    : [
        hasPlayPassTrial(parkCode) && !isPlayPassRestricted
          ? {
              label: 'Play Pass',
              options: [
                {
                  label: 'Include option to purchase a Play Pass Online',
                  value: 'include_option_to_purchase_a_play_pass_online',
                },
              ],
            }
          : null,
        ownersCards.nominees.length > 0
          ? {
              label: 'Friends & Family Card Holder',
              options: ownersCards.nominees.map((card: any) => ({
                label: `${card.firstName} ${card.lastName}`,
                value: card.id,
              })),
            }
          : null,
      ].filter((option) => !!option);
};
