import React from 'react';
import { useRouter } from 'next/router';
import {
  Let2OwnBreaksSummary,
  MODE,
} from '@havenengineering/module-owners-shared-library/dist/components/Let2OwnBreakSummary';
import useSWR from 'swr';

import { swrFetcher } from '../../../helpers/fetch';
import { DirectDebitBalanceType } from '../../../types/finance/directDebit/directDebit';
import { LettingConfigEntry } from '../../../types/lettingConfig';
import { Let2OwnSummary } from '../../helpers/bookings';
import { displayOneOffPayment } from '../../Let2OwnProgressStepper/helpers';

export interface Let2OwnBreakSummaryProps {
  isLoading: boolean;
  let2OwnSummary: Let2OwnSummary;
  currentDate: string;
  mode: MODE;
  skipPayment?: boolean;
}

export const Let2OwnBreaksSummaryWrapper: React.FC<
  Let2OwnBreakSummaryProps
> = ({ isLoading, let2OwnSummary, currentDate, mode, skipPayment = false }) => {
  const router = useRouter();

  const { data: config } = useSWR<LettingConfigEntry[]>(
    '/letting/letting-config?name=let2ownPartialPaymentDateRange',
    swrFetcher,
    {
      revalidateOnMount: true,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );

  const handleRenewalClick = () => {
    if (skipPayment) {
      router.push('/renewal/confirmation');
    } else if (displayOneOffPayment(config, currentDate)) {
      router.push('/finance/partial-payment');
    } else {
      router.push(
        `/finance/direct-debit/setup?balanceType=${DirectDebitBalanceType.Let2Own}`
      );
    }
  };

  return (
    <Let2OwnBreaksSummary
      isLoading={isLoading}
      let2OwnSummary={let2OwnSummary}
      mode={mode}
      handleRenewalClick={handleRenewalClick}
    />
  );
};
